.page-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
}

.container {
  display: flex;
  max-width: 100vw;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.twitter {
  transform: scale(0.5);
  filter: grayscale(1);
}

.twitter:hover {
  transform: scale(1);
  filter: grayscale(0);
  cursor: pointer;
}

.padded {
  margin: 0.3rem;
}
